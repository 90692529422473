import { createSlice } from "@reduxjs/toolkit";
import stepApi from "../../../api/stepApi";
const state = {
  steps: [],
};

const stepSlice = createSlice({
  name: "stepSlice",
  initialState: state,
  reducers: {
    //Actionを記述すｒ
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    add: (state, action) => {
      state.steps.push(action.payload);
      stepApi.add(action.payload);
    },
    remove: (state, action) => {
      // state.steps = state.steps.filter((step) => step.id !== action.payload);
      // console.log("state.steps", state.steps);
      state.steps = state.steps.filter((step) => {
        console.log("action.payload", action.payload);
        console.log("step.id", step.id);
        console.log("step", step);
        return step.id !== action.payload;
      });
      stepApi.destroyStep(action.payload);
    },
    updateComplete: (state, action) => {
      state.steps = state.steps.map((step) =>
        step.id === action.payload.id
          ? { ...step, contents: action.payload.contents }
          : step
      );
    },
  },
});

export const { setSteps, add, remove, updateComplete } = stepSlice.actions;
export default stepSlice;
