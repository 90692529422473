import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  FormHelperText,
  Textarea,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
// import { createTodoApi } from "../../stores/slices/todo/todoAPI";
import { axiosInstance } from "../../utils/axios";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { add } from "../store/modules/stepSlice";

const AddStep = () => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(add(data));
    reset();
  };

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column">
          <FormControl w="100%">
            <FormHelperText>工程/箇所：</FormHelperText>
            <Input
              id="title"
              mt={1}
              placeholder="例:ひさし１回目/ひび割れ"
              {...register("title", { required: "Please enter todo." })}
            />
            <Button height={6} onClick={onToggle}>
              <FormHelperText>補足説明：</FormHelperText>
            </Button>
            <Collapse in={isOpen} animateOpacity>
              <Textarea
                placeholder="Here is a sample placeholder"
                id="contents"
                {...register("contents")}
              />
            </Collapse>
          </FormControl>
          <Box w="15%" display="flex" justifyContent="end" alignItems="end">
            <Button
              mt={1}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              追加
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default AddStep;
