import {
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import Support from "./Support";
import Genba from "./Genba";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { setSteps } from "../store/modules/stepSlice";
import stepApi from "../../api/stepApi";
import { actions, setPosts } from "../store/modules/posts";

import { AuthContext } from "../../App";

const Main = () => {
  const dispatch = useDispatch();
  const fetch = async () => {
    stepApi.getAll().then((data) => {
      dispatch(setSteps(data));
    });

    //image取得
    const res = await actions.fetchPosts();
    dispatch(setPosts(res.data));
  };

  // const destroyStep = async (id) => {
  //   await axios.delete(`http://localhost:3010/steps/${id}`);
  //   fetch();
  // };

  useEffect(() => {
    // setSteps(initialTasks);
    fetch();
  }, []);

  const { isSignedIn, currentUser } = useContext(AuthContext);

  return (
    <div className="App">
      {/* {isSignedIn && currentUser ? ( */}
      <Container maxW="100%">
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>現場</Tab>
            <Tab>サポート</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Genba />
            </TabPanel>
            <TabPanel>
              <Support />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      {/* ) : (
        <h1>Not signed in</h1>
      )} */}
    </div>
  );
};

export default Main;
