import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import AddStep from "../components/AddStep";
// import StepPresenter from "../components/StepPresenter";
import StepList from "../components/StepList";

const Support = (props) => {
  return (
    <Flex direction="column">
      <AddStep />
      {/* <AddStep fetch={fetch} /> */}

      <h2>登録工程</h2>
      <StepList isDispImg={false} />
    </Flex>
  );
};

export default Support;
