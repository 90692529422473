import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Icon,
  HStack,
  StackDivider,
  Image,
  Text,
} from "@chakra-ui/react";
// import Dropzone from "./DropZone";
import Dropzone, { useDropzone } from "react-dropzone";
import { actions } from "../store/modules/posts";

import { RiCameraLine, RiImageAddLine } from "react-icons/ri";
import liff from "@line/liff";
import "react-google-photo/styles.css";

function PictureField(posts) {
  const onTakePicture = () => {
    liff.openWindow({
      url: "https://line.me/R/nv/camera/",
      external: false,
    });
  };
  const onOpenCameraRoll = () => {
    liff.openWindow({
      url: "https://line.me/R/nv/cameraRoll/multi",
      external: false,
    });
  };
  const dispatch = useDispatch();
  const upload = async (acceptedFiles, stepId) => {
    const formData = new FormData();
    formData.append("title", "テストテスト3");
    formData.append("step_id", stepId);
    formData.append("image", acceptedFiles[0]);
    dispatch(actions.createPost(formData));
  };

  const [urls, setUrls] = useState([]);

  const onDrop = useCallback((acceptedFiles, stepId, urls) => {
    if (acceptedFiles) {
      const newUrls = [
        ...urls,
        acceptedFiles.map((acceptedFile) => URL.createObjectURL(acceptedFile)),
      ];
      console.log("newUrls", newUrls);
      setUrls(newUrls);
      //アップロード処理
      upload(acceptedFiles, stepId);
    }
  }, []); // 空配列を指定したのでonDropのインスタンスはコンポーネントの破棄まで更新されない

  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="scroll">
      <h1>{posts.step_id}</h1>
      <Flex>
        <Flex direction="column" width={10}>
          <Icon
            maxW={{ base: "100%", sm: "200px" }}
            as={RiCameraLine}
            color="teal"
            cursor="pointer"
            h={6}
            mr={2}
            w={6}
            onClick={onTakePicture}
          />
          <Dropzone
            onDrop={(acceptedFiles) =>
              onDrop(acceptedFiles, posts.step_id, urls)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Icon
                    maxW={{ base: "100%", sm: "200px" }}
                    as={RiImageAddLine}
                    color="teal"
                    cursor="pointer"
                    h={6}
                    mr={2}
                    w={6}
                  />
                </div>
              </section>
            )}
          </Dropzone>
        </Flex>

        <HStack
          divider={<StackDivider borderColor="gray.200" />}
          align="stretch"
          w={{ base: "90vw", sm: "80vw", md: "70vw", lg: "60vw" }}
          border="1px"
          borderColor="gray.300"
          borderRadius="md"
          p={2}
          maxH="30vh"
          overflow="scroll"
        >
          {posts.posts.length &&
            posts.posts.map((post, index) => {
              console.log("post.image_url", post.image_url);
              return (
                post.step_id === posts.step_id && (
                  <Box key={index}>
                    <Image
                      src={post.image_url}
                      boxSize="80px"
                      objectFit="cover"
                      key={index}
                    />
                  </Box>
                )
              );
            })}

          {urls.map((url) => (
            <Box>
              <Image src={url} boxSize="80px" objectFit="cover" />
            </Box>
          ))}
        </HStack>
      </Flex>
    </Box>
  );
}

export default PictureField;
