const auth_headers = {
  userId: "",
  client: "",
  accessToken: "",
  expiry: 0,
};

// 保存しているアクセストークンなどからリクエストヘッダを生成
export function getAuthHeaders() {
  return {
    uid: auth_headers.userId,
    client: auth_headers["client"],
    expiry: auth_headers.expiry,
    "access-token": auth_headers.accessToken,
  };
}
export default auth_headers;
