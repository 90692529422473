import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { getAuthHeaders } from "../../../api/auth_headers";

const ENDPOINT_URL = process.env.REACT_APP_API_DOMEIN;

// const apiUrlBase = "http://localhost:3010/api/posts";
const apiUrlBase = ENDPOINT_URL + "/api/posts";
const headers = { "Content-Type": "multipart/form-data", ...getAuthHeaders() };
// const headers = { "Content-Type": "multipart/form-data" };

const state = {
  posts: [],
};

const postSlice = createSlice({
  name: "postSlice",
  initialState: state,
  reducers: {
    getters: (state) => {
      state.posts.sort((a, b) => b.id - a.id);
    },
    setPosts: (state, posts) => {
      state.posts = posts.payload;
    },
    appendPost: (state, post) => {
      state.posts = [...state.posts, post];
    },
    removePost: (state, id) => {
      state.posts = state.posts.filter((post) => post.id !== id);
    },
  },
});

export const actions = {
  async fetchPosts() {
    try {
      const response = await axios.get(`${apiUrlBase}`, {
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(),
        },
      });
      return response;
      // axios.get(`${apiUrlBase}`).then((data) => {
      // return data;
      // });
    } catch (e) {
      console.error(e);
    }
  },
  async createPost(post) {
    try {
      console.log("ここを通過");
      const response = await axios.post(`${apiUrlBase}`, post, headers);
    } catch (e) {
      console.error(e);
    }
  },
  async deletePost({ commit }, id) {
    try {
      axios.delete(`${apiUrlBase}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(),
        },
      });
      commit("removePost", id);
    } catch (e) {
      console.error(e);
    }
  },
};

export const { getters, setPosts, appendPost, removePost } = postSlice.actions;
export default postSlice;

// export default {
//   namespaced: true,
//   state,
//   getters,
//   mutations,
//   actions,
// };
