import { useSelector, useDispatch } from "react-redux";
import { remove, updateComplete } from "../store/modules/stepSlice";
import {
  Box,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import {
  RiAlignJustify,
  RiArrowDownSLine,
  RiArrowDownSFill,
} from "react-icons/ri";

const Step = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  // const { destroyTask } = props;
  const dispatch = useDispatch();
  return (
    <Box mb="16px">
      <Flex>
        <Menu>
          <MenuButton width="10%">
            <Icon
              as={RiAlignJustify}
              color="teal"
              cursor="pointer"
              h={6}
              mr={2}
              w={6}
            />
          </MenuButton>
          <MenuList>
            <MenuItem>上へ移動</MenuItem>
            <MenuItem>下へ移動</MenuItem>
            <MenuItem>コピー</MenuItem>
            <MenuItem onClick={() => dispatch(remove(props.id))}>削除</MenuItem>
          </MenuList>
        </Menu>
        <Editable
          defaultValue={props.title}
          width="80%"
          // onEdit={() =>
          //   dispatch(updateComplete({ id: props.id, contents:  }))
          // }
        >
          <EditablePreview />
          <EditableInput
          // onChange={
          //   onChangeHandler
          //   // (e) =>
          //   // dispatch(
          //   //   updateComplete({ id: props.id, contents: e.target.value })
          //   // )
          // }
          />
        </Editable>
        <Icon
          width="10%"
          as={props.contents.length ? RiArrowDownSFill : RiArrowDownSLine}
          color="teal"
          cursor="pointer"
          h={6}
          mr={2}
          w={6}
          onClick={onToggle}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Editable defaultValue={props.contents.length ? props.contents : "__"}>
          <EditablePreview />
          <EditableTextarea />
        </Editable>
      </Collapse>
    </Box>
  );
};

export default Step;
