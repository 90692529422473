import applyCaseMiddleware from "axios-case-converter";
import axios from "axios";
const ENDPOINT_URL = process.env.REACT_APP_API_DOMEIN;
// applyCaseMiddleware:
// axiosで受け取ったレスポンスの値をスネークケース→キャメルケースに変換
// または送信するリクエストの値をキャメルケース→スネークケースに変換してくれるライブラリ

// ヘッダーに関してはケバブケースのままで良いので適用を無視するオプションを追加
const options = {
  ignoreHeaders: true,
};

const client = applyCaseMiddleware(
  axios.create({
    // baseURL: "http://localhost:3010/api/",
    // baseURL: "https://api.line-gempo.com/api/",
    baseURL: ENDPOINT_URL + "/api/",
  }),
  options
);

export default client;
