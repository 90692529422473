import client from "./client";
import Cookies from "js-cookie";

// サインアップ（新規アカウント作成）
export const signUp = (userId, accessToken) => {
  const body = JSON.stringify({
    uid: userId,
    access_token: accessToken,
  });
  const header = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return client.post("auth", body, header);
};

// サインイン（ログイン）
export const signIn = (userId, accessToken) => {
  const body = JSON.stringify({
    uid: userId,
    access_token: accessToken,
  });
  const header = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return client.post("auth/sign_in", body, header);
};

// サインアウト（ログアウト）
export const signOut = () => {
  return client.delete("auth/sign_out", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
};

// 認証済みのユーザーを取得
export const getCurrentUser = () => {
  if (
    !Cookies.get("_access_token") ||
    !Cookies.get("_client") ||
    !Cookies.get("_uid")
  ) {
    return;
  }
  console.log('client.get("/auth/sessions"', client.get("/auth/sessions"));
  return client.get("/auth/sessions", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
};
