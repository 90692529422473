import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Main from "./views/pages/Main";
import { getCurrentUser } from "./api/auth";
import PrivateRoute from "./views/components/routing/PrivateRoute";

import { Provider } from "react-redux";
import { store } from "./views/store/store";
// グローバルで扱う変数・関数
export const AuthContext = createContext({});

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  // 認証済みのユーザーがいるかどうかチェック
  // 確認できた場合はそのユーザーの情報を取得
  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser();
      console.log("res", res);
      if (res?.data.isLogin === true) {
        setIsSignedIn(true);
        setCurrentUser(res?.data.data);

        console.log(res?.data.data);
      } else {
        console.log("No current user");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    handleGetCurrentUser();
  }, [setCurrentUser]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            loading,
            setLoading,
            isSignedIn,
            setIsSignedIn,
            currentUser,
            setCurrentUser,
          }}
        >
          <Routes>
            <Route exact path="/" element={<Main />} />
          </Routes>
        </AuthContext.Provider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
