import { StackDivider, VStack, Box } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import PictureField from "./PictureField";
import Step from "./Step";

const StepList = ({ isDispImg = true }) => {
  const steps = useSelector((state) => state.steps.steps);
  const posts = useSelector((state) => state.posts.posts);

  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" />}
      align="stretch"
      w={{ base: "90vw", sm: "80vw", md: "70vw", lg: "60vw" }}
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      p={2}
      // maxH="65vh"
      overflow="scroll"
    >
      {steps.map((step, index) => {
        return (
          <Box key={index}>
            <Step
              id={step.id}
              key={index}
              index={index}
              title={step.title}
              contents={step.contents}
            />
            {isDispImg && (
              <PictureField posts={posts} key={step.id} step_id={step.id} />
            )}
          </Box>
        );
      })}
    </VStack>
  );
};

export default StepList;
