import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./views/pages/Main";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import { signIn, signUp } from "./api/auth";
import { ChakraProvider } from "@chakra-ui/react";
import liff from "@line/liff";
import LIFFInspectorPlugin from "@line/liff-inspector";
import auth_headers from "./api/auth_headers";

liff.use(new LIFFInspectorPlugin());

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = {
  userId: "",
  client: "",
  accessToken: "",
  expiry: 0,
};
const LIFF_ID = "1657927433-J7pr9M35";

const saveStore = (res) => {
  // 認証に成功したときにアクセストークンなどを保存
  auth_headers["client"] = res.headers.get("client");
  auth_headers["accessToken"] = res.headers.get("access-token");
  auth_headers["expiry"] = res.headers.get("expiry");
};

const lineSetUp = async function () {
  liff.getProfile().then((profile) => {
    console.log("profile", profile);
  });

  const liffContext = liff.getContext();
  if (!liffContext || !liffContext.userId) {
    window.alert("コンテキストの取得に失敗しました。");
    return;
  }
  console.log("LIFF_ID", LIFF_ID);
  const userId = liffContext.userId;
  auth_headers.userId = userId;
  const accessToken = await liff.getAccessToken();
  if (!accessToken) {
    window.alert("LIFFの認証に失敗しました。");
    return;
  }
  const res1 = await signIn(userId, accessToken);
  console.log("res1", res1);
  if (res1 && res1.status === 200) {
    // context.emit("login");
    console.log("login-by-signin");
    saveStore(res1);
    return;
  }
  const res2 = await signUp(userId, accessToken);
  console.log("res2", res2);
  if (res2 && res2.status === 200) {
    // context.emit("login");
    console.log("login-by-sing-up");
    saveStore(res2);
    return;
  }
  window.alert("認証に失敗しました。");
};
liff
  .init({
    liffId: LIFF_ID, // Use own liffId
    withLoginOnExternalBrowser: true,
  })
  .then(lineSetUp)
  // .getProfile()
  // .then((profile) => {
  //   console.log("profile", profile);
  //   const name = profile.displayName;
  // })
  .then(
    // lineSetUp
    () => {
      root.render(
        <React.StrictMode>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </React.StrictMode>
      );
    }
  );

// async function getHelloMessage() {
//   // 保存したアクセストークンを使用し認証必須のAPIを呼んでいる
//   const res = await fetch("/hello", {
//     headers: {
//       "Content-Type": "application/json",
//       ...getAuthHeaders(),
//     },
//   });
//   if (res.status !== 200) {
//     const data = await res.json();
//     console.error(data);
//     throw new Error(`Status Code: ${res.status}.`);
//   }
//   const data = await res.json();
//   return data.message;
// }
//Line ログイン　ここから
// async function signIn2(userId, accessToken) {
//   const res = await fetch("/api/auth/sign_in", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       uid: userId,
//       access_token: accessToken,
//     }),
//   });
//   if (res.status === 200) {
//     auth_headers["client"] = res.headers.get("client");
//     auth_headers["accessToken"] = res.headers.get("access-token");
//     return;
//   }
//   if (res.status !== 401 && res.status !== 404) {
//     const data = await res.json();
//     console.error(data);
//     throw new Error(`Status Code: ${res.status}.`);
//   }
// }
// async function signUp2(userId, accessToken) {
//   const res = await fetch("/api/auth", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       uid: userId,
//       access_token: accessToken,
//     }),
//   });
//   if (res.status !== 200) {
//     const data = await res.json();
//     console.error(data);
//     throw new Error(`Status Code: ${res.status}.`);
//   }
//   // 認証に成功したときにアクセストークンなどを保存
//   store.client = res.headers.get("client");
//   store.accessToken = res.headers.get("access-token");
//   store.expiry = res.headers.get("expiry");
// }

// // 保存しているアクセストークンなどからリクエストヘッダを生成
// export function getAuthHeaders() {
//   return {
//     uid: store.userId,
//     client: auth_headers["client"],
//     expiry: store.expiry,
//     "access-token": store.accessToken,
//   };
// }
// ここまで

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
