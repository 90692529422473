import axios from "axios";
import { getAuthHeaders } from "./auth_headers";

const ENDPOINT_URL = process.env.REACT_APP_API_DOMEIN;
// axios.defaults.headers.common["X-CSRF-Token"] = csrfToken();

const stepApi = {
  async getAll() {
    const res = await axios.get(ENDPOINT_URL + "/steps", {
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    });
    // const res = await axios.get(ENDPOINT_URL + "/steps");
    return res.data;
  },
  async fetch() {
    const res = await axios.get(ENDPOINT_URL + "/steps", {
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    });
    // const res = await axios.get(ENDPOINT_URL + "/steps");
    return res.data;
  },

  async add(data) {
    await axios.post(
      ENDPOINT_URL + "/steps",
      {
        title: data.title,
        contents: data.contents,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(),
        },
      }
    );
  },

  async destroyStep(id) {
    const res = await axios.delete(ENDPOINT_URL + `/steps/${id}`, {
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    });
    // const res = await axios.delete(`http://localhost:3010/steps/${id}`);
    return res.data;
  },
};

export default stepApi;
